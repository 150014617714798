<template>
  <base-section id="gallery">
    <v-tabs
      v-model="tab"
      centered
      class="mb-12"
    >
      <v-tab
        v-for="(category) in categories"
        :key="category.value"
        :href="`#${category.value}`"
        class="text-capitalize"
      >
        {{ category.text }}
      </v-tab>
    </v-tabs>

    <v-container>
      <v-fade-transition
        group
        hide-on-leave
        tag="v-row"
      >
        <v-col
          v-for="(project, i) in projects"
          :key="i"
          class="gallery-card"
          cols="12"
          sm="6"
          md="4"
        >
          <project-card
            :id="project.id"
            v-bind="project"
            :subtitle="getCategoriesText(project.categories)"
            :src="project.imageUrl"
            height="300"
            width="100%"
          />
        </v-col>
      </v-fade-transition>
    </v-container>
  </base-section>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'SectionGallery',

    components: {
      ProjectCard: () => import('@/components/ProjectCard'),
    },

    data: () => ({
      tab: 0,
      tabs: [],
      categories: [
      ],
      items: [],
    }),

    computed: {
      projects () {
        const tab = parseInt(this.tab)
        if (tab === 0) return this.items

        return this.items.filter(project => {
          return project.categories.indexOf(tab) >= 0
        })
      },
    },

    mounted () {
      axios.get('https://www.ironwood.ch/cms/public/ironwood/items/gallery_article?fields=id,title,cover_image.private_hash,categories.gallery_category_id&sort=-created_on&filter[status]=published').then((response) => {
        if (response.status === 200 && typeof response.data !== 'undefined') {
          const data = []
          for (let i = 0; i < response.data.data.length; i++) {
            const row = response.data.data[i]

            const final = {
              id: row.id,
              title: row.title,
              categories: [],
              imageUrl: 'https://www.ironwood.ch/cms/public/ironwood/assets/' + row.cover_image.private_hash,
            }

            for (let j = 0; j < row.categories.length; j++) {
              final.categories.push(row.categories[0].gallery_category_id)
            }
            data.push(final)
          }
          this.items = data
        }
      })

      axios.get('https://www.ironwood.ch/cms/public/ironwood/items/gallery_category?fields=id,name&sort=sort&filter[status]=published').then((response) => {
        if (response.status === 200 && typeof response.data !== 'undefined') {
          const categories = [{
            value: 0,
            text: 'Tout',
          }]

          for (let i = 0; i < response.data.data.length; i++) {
            const row = response.data.data[i]
            categories.push({
              value: row.id,
              text: row.name,
            })
          }

          this.categories = categories
        }
      })
    },
    methods: {
      getCategoriesText (ids) {
        const final = []
        for (let i = 0; i < this.categories.length; i++) {
          if (ids.indexOf(this.categories[i].value) >= 0) {
            final.push(this.categories[i].text)
          }
        }
        return final.join(' / ')
      },
    },
  }
</script>

<style lang="sass">
  .gallery-card
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)
</style>
